import React from "react";
import ReactDOM from "react-dom/client";
import "./css/index.css";
import {App} from "./app";
import KeepAwake from "@sayem314/react-native-keep-awake";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

const USE_STRICT = false;

let rootNode: React.JSX.Element;

if (USE_STRICT) {
    rootNode = <React.StrictMode><App/></React.StrictMode>;
} else {
    rootNode = <App/>;
}

root.render(rootNode);

