import React from 'react';
import {AppBar, Box, createTheme, CssBaseline, Toolbar, Typography} from "@mui/material";
import {Theme, ThemeProvider} from "@emotion/react";
import {BehaviorSubject} from "rxjs";
import {AppWindow} from "./app-window";
import {AppComponentStates, DisplayMode} from "./app-component";
import {Metronome} from "./metronome";
import {Buffer} from "buffer";
//
import SettingsIcon from "@mui/icons-material/Settings";
import {BeatCircleBox} from "./beat-circle-box";
import {InputControls} from "./input-controls";
import {PlayControls} from "./play-controls";
import {Settings} from "./settings";
import NoSleep from 'nosleep.js';
import messages from "./messages";
import {FormattedMessage, IntlProvider} from 'react-intl';
//
import './css/app.css';
import KeepAwake from "@sayem314/react-native-keep-awake";

export interface VersionRecord {
    build?: number;
    buildDate?: string;
    commit?: string;
    commitShort?: string;
    startTime: number;
    version?: string;
}

export interface Note {
    label: string;
    length: number;
}

export interface Tempo {
    bpmHigh: number;
    bpmLow: number;
    description: string;
    name: string;
}

export interface SoundSet {
    name: string;
    sounds: string[];
    type: "mp3" | "json";
}

export interface SoundSetMp3 extends SoundSet {
    type: "mp3";
}

export interface SoundSetJson extends SoundSet {
    instrument: string;
    type: "json";
}

export interface Signature {
    activePattern: string;
    beats: number;
    common: boolean;
    patterns: string[];
    timeBase: number;
}

export interface PatternTypes {
    beatCount: number;
    pattern: string;
    speedBaseNote: string;
    types: number[];
}

export interface CustomPattern extends PatternTypes {
    timeSignature: string;
}

export interface ActiveSignature {
    active: string;
    activePatternTypes: PatternTypes;
    beats: number;
    common: boolean;
    patternTypes: PatternTypes[];
    speedBaseNote: string;
    timeBase: number;
    timeSignature: string;
}

export interface SoundSetInfo {
    maxBpm: number;
    minBpm: number;
    notes: Note[];
    rests: Note[];
    signatures: Signature[];
    soundFontRootUrl: string;
    soundSets: {
        [soundSetName: string]: SoundSetJson | SoundSetMp3;
    };
    tempi: Tempo[];
    volumeGainFactor: number;
}

export interface MainProps {
}

export interface MainStates extends AppComponentStates {
    activeBeat: number;
    activePattern: string;
    activeTimeSignature: string;
    appReady: boolean;
    bpm: number;
    customPatterns: CustomPattern[];
    flashBackground: boolean;
    locale: string;
    settingsOpen: boolean;
    showNumbers: boolean;
    soundCheck: string;
    soundSetId: string;
    volume: number;
}

export class App extends AppWindow<MainProps, MainStates> {
    public static onAppReady: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public static ref: App;
    public static versionInfo: VersionRecord;
    //
    public audioContext: AudioContext;
    public volumeControl: GainNode;
    //
    private activeSignature: ActiveSignature;
    private backgroundFlashTimer: NodeJS.Timeout;
    private debugMessage: string = "";
    private metronome: Metronome;
    private rootNode: HTMLDivElement;
    private soundSet: AudioBuffer[];
    private soundSetInfo: SoundSetInfo;

    constructor(props: MainProps) {
        super(props, {persistentKeys: ["volume", "bpm", "soundSetId", "customPatterns", "activeTimeSignature", "activePattern", "flashBackground", "locale", "showNumbers"]});

        App.ref = this;

        this.audioContext = new AudioContext();

        this.volumeControl = this.audioContext.createGain();
        this.volumeControl.connect(this.audioContext.destination);

        this.rootNode = document.getElementById("root") as HTMLDivElement;

        let sound: AudioBufferSourceNode;
        this.metronome = new Metronome();
        this.metronome.onBeat.subscribe(async () => {
            let activeBeat = (this.state.activeBeat + 1) % this.activeSignature.beats;
            if (activeBeat === 0) {
                activeBeat = 0;
            }

            if (sound) {
                sound.stop(this.audioContext.currentTime);
            }

            const type = this.activeSignature.activePatternTypes.types[activeBeat];
            if (type > 0) {
                if (this.state.flashBackground) {
                    this.flashBackground();
                }

                sound = this.audioContext.createBufferSource();
                sound.buffer = this.soundSet[type];
                sound.connect(this.volumeControl).connect(this.audioContext.destination);
                sound.start(this.audioContext.currentTime, 0);
            }

            this.setState({
                activeBeat: activeBeat
            });
        });

        this.loadSoundSetInfo();

        const noSleep: NoSleep = new NoSleep();

        document.addEventListener('click', async function enableNoSleep() {
            document.removeEventListener('click', enableNoSleep, false);
            // self.debugMessage = "no sleep enabled 1";
            await noSleep.enable();
        }, false);

        window.addEventListener("blur", () => {
            if (document.hidden || navigator.userAgent.match(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/)) {
                this.metronome.stop();
                this.setState({
                    activeBeat: -1
                });
            }
        });
    }

    public getDefaultStates(): MainStates {
        return super.getDefaultStates({
            activeBeat: -1,
            activePattern: null,
            activeTimeSignature: "3/4",
            appReady: false,
            bpm: 120,
            customPatterns: [],
            flashBackground: false,
            settingsOpen: false,
            soundCheck: "C3",
            soundSetId: "woodblock-1",
            volume: 0.5,
            showNumbers: true
        } as MainStates);
    }

    public setState<K extends keyof MainStates>(state?: ((prevState: Readonly<MainStates>, props: Readonly<MainProps>) => Pick<MainStates, K> | MainStates | null) | (Pick<MainStates, K> | MainStates | null), callback?: () => void) {
        super.setState(Object.assign({} as MainStates, state ?? {}), () => {
            this.volumeControl.gain.value = this.state.volume;
            if (callback) {
                callback();
            }
        });
    }

    public render() {
        const theme: Theme = createTheme({
            palette: {
                mode: this.getStateValue("displayMode") as DisplayMode
            }
        });

        if (this.state.appReady) {
            return (
                <IntlProvider locale={this.state.locale} messages={messages[this.state.locale]}>
                    <ThemeProvider theme={theme}>
                        <KeepAwake/>
                        <CssBaseline/>
                        <AppBar position="static" style={{display: (window.navigator as any).standalone ? "block" : "none", marginBottom: "30px"}}>
                            <Toolbar>
                                <Typography
                                    variant="h6"
                                    component="div"
                                    sx={{flexGrow: 1}}
                                >
                                    <FormattedMessage id="Metronome"/>
                                </Typography>
                                <SettingsIcon onClick={() => {
                                    this.setState({
                                        settingsOpen: true
                                    });
                                }}/>
                            </Toolbar>
                        </AppBar>
                        <Box style={{display: (window.navigator as any).standalone ? "none" : "block", height: "60px", textAlign: "right"}}>
                            <SettingsIcon
                                fontSize="large"
                                style={{margin: "20px"}}
                                onClick={() => {
                                    this.setState({
                                        settingsOpen: true
                                    });
                                }}
                            />
                        </Box>
                        <Box style={{display: "flex", flexDirection: "column", marginLeft: "10px", marginRight: "10px"}}>
                            <BeatCircleBox
                                activeSignature={this.activeSignature}
                                activeBeat={this.state.activeBeat}
                                soundSetLength={this.soundSet.length}
                                showNumbers={this.state.showNumbers}
                                updatePatternTypes={(activePatternTypes: PatternTypes) => {
                                    if (!activePatternTypes.pattern.match(/^C:/)) {
                                        activePatternTypes.pattern = "C:" + activePatternTypes.types.join(",");
                                        this.activeSignature.patternTypes.push(this.getPatternTypes(activePatternTypes.pattern));
                                    }

                                    this.activeSignature.active = activePatternTypes.pattern;
                                    this.activeSignature.activePatternTypes = this.activeSignature.patternTypes.find(p => p.pattern.match(/^C:/));
                                    Object.assign(this.activeSignature.activePatternTypes, activePatternTypes);

                                    const customPattern = this.state.customPatterns.find(p => p.timeSignature == this.activeSignature.timeSignature);
                                    if (customPattern) {
                                        Object.assign(customPattern, activePatternTypes);
                                    } else {
                                        this.state.customPatterns.push({
                                            pattern: activePatternTypes.pattern, beatCount: activePatternTypes.beatCount, speedBaseNote: activePatternTypes.speedBaseNote, types: activePatternTypes.types, timeSignature: this.activeSignature.timeSignature
                                        });
                                    }

                                    this.setState({
                                        activePattern: activePatternTypes.pattern, customPatterns: this.state.customPatterns
                                    });
                                }}
                            />
                            <InputControls
                                activeSignature={this.activeSignature}
                                bpm={this.state.bpm}
                                soundSetInfo={this.soundSetInfo}
                                updateBpm={(bpm: number) => {
                                    this.setState({
                                        bpm: bpm
                                    }, () => {
                                        this.metronome.setBpm(bpm * this.activeSignature.beats / this.activeSignature.activePatternTypes.beatCount);
                                    });
                                }}
                                updateSignature={(signature) => {
                                    this.activeSignature = this.getSignaturePattern(signature.beats + "/" + signature.timeBase);
                                    this.setState({
                                        activePattern: this.activeSignature.active, activeTimeSignature: this.activeSignature.timeSignature
                                    }, () => {
                                        this.metronome.setBpm(this.state.bpm * this.activeSignature.beats / this.activeSignature.activePatternTypes.beatCount);
                                    });
                                }}
                                updatePattern={(pattern: string) => {
                                    this.activeSignature.active = pattern;
                                    this.activeSignature.activePatternTypes = this.activeSignature.patternTypes.find(p => p.pattern == pattern);
                                    this.setState({
                                        activePattern: this.activeSignature.active
                                    }, () => {
                                        this.metronome.setBpm(this.state.bpm * this.activeSignature.beats / this.activeSignature.activePatternTypes.beatCount);
                                    });
                                }}
                            />
                            <PlayControls
                                active={this.metronome.isActive}
                                volume={this.state.volume}
                                volumeChange={(volume: number) => {
                                    this.setStateValue("volume", volume);
                                }}
                                startStop={async () => {
                                    if (this.audioContext.state === "suspended") {
                                        await this.audioContext.resume();
                                        this.volumeControl.gain.value = 0;
                                        await new Promise<void>((resolve) => setTimeout(() => resolve(), 300));
                                    }

                                    if (this.metronome.isActive) {
                                        this.metronome.stop();
                                        this.setState({
                                            activeBeat: -1
                                        });
                                    } else {
                                        console.log("bcd", this.activeSignature);
                                        this.metronome.setBpm(this.state.bpm * this.activeSignature.beats / this.activeSignature.activePatternTypes.beatCount);
                                        this.metronome.start();
                                    }
                                }}
                            />
                        </Box>
                        <Settings
                            open={this.state.settingsOpen}
                            soundSetId={this.state.soundSetId}
                            soundSetInfo={this.soundSetInfo}
                            displayMode={this.state.displayMode}
                            flashBackground={this.state.flashBackground}
                            showNumbers={this.state.showNumbers}
                            onShowNumbersChange={() => {
                                this.setState({
                                    showNumbers: !this.state.showNumbers
                                });
                            }}
                            onFlashBackgroundChange={() => {
                                this.setState({
                                    flashBackground: !this.state.flashBackground
                                });
                            }}
                            onDisplayModeChange={() => {
                                this.setState({
                                    displayMode: this.state.displayMode == DisplayMode.Dark ? DisplayMode.Light : DisplayMode.Dark
                                });
                            }}
                            onClose={() => {
                                this.setState({
                                    settingsOpen: false
                                });
                            }}
                            onReset={() => {
                                this.metronome.stop();
                                this.setState({
                                    customPatterns: []
                                }, () => {
                                    this.loadSoundSetInfo();
                                });
                            }}
                            updateSoundSet={(soundSetId) => {
                                this.setState({
                                    soundSetId: soundSetId
                                }, () => {
                                    this.loadSoundSet(soundSetId).then((soundSet) => {
                                        this.soundSet = soundSet;
                                    }).catch((error) => {
                                        console.error("unable to change sound set", error);
                                    });
                                });
                            }}
                        />
                        <Box style={{flexGrow: 1}}>{this.debugMessage}</Box>
                    </ThemeProvider>
                </IntlProvider>
            );
        }
    }

    private flashBackground() {
        if (this.backgroundFlashTimer) {
            clearTimeout(this.backgroundFlashTimer);
            this.rootNode.classList.remove("fade-out-background");

        }

        this.rootNode.classList.add("fade-out-background");
        this.backgroundFlashTimer = setTimeout(() => {
            this.rootNode.classList.remove("fade-out-background");
        }, 150);
    }

    private getPatternTypes(pattern: string, beats: number = this.activeSignature.beats, timeBase: number = this.activeSignature.timeBase): PatternTypes {
        let types: number[];
        let beatCount: number;
        if (pattern.match(/^C:/)) {
            types = pattern.replace(/^C:/, "").split(",").map(p => parseInt(p));
            beatCount = beats;
        } else {
            const match = pattern.match(/[:+-]/);
            const separator: string = match ? match[0] : ":";
            types = [];
            let patternParts: number[] = pattern.replace(/[:+]/g, "-").split("-").map(p => parseInt(p));
            patternParts.forEach((step) => {
                if (step === 0) {
                    types.push(0);
                } else {
                    types.push(1);
                }
                for (let i = 1; i < step; i++) {
                    if (separator == ":") {
                        types.push(2);
                    } else if (separator == "+" || separator == "-") {
                        types.push(0);
                    } else {
                        types.push(3);
                    }
                }
            });

            beatCount = pattern.split("-").length;
            if (beatCount == 1) {
                beatCount = beats;
            }
        }

        const baseLength = 1 / timeBase * beats / beatCount;
        let baseNote = this.soundSetInfo.notes.find(n => n.length == baseLength);
        if (!baseNote) {
            baseNote = this.soundSetInfo.notes.find(n => n.length == 1 / timeBase);
        }

        return {
            beatCount: beatCount,
            pattern: pattern,
            speedBaseNote: baseNote ? baseNote.label : "?",
            types: types
        };
    }

    private getSignaturePattern(timeSignature: string, activePattern?: string): ActiveSignature {
        const [beats, timeBase] = timeSignature.split("/").map(v => parseInt(v));
        const signature = this.soundSetInfo.signatures.find(s => s.beats == beats && s.timeBase == timeBase);
        let pattern: ActiveSignature;
        if (signature) {
            if (!activePattern) {
                activePattern = signature.activePattern;
            }

            const patternTypes = signature.patterns.map((pattern: string): PatternTypes => {
                return this.getPatternTypes(pattern, signature.beats, signature.timeBase);
            });

            const activePatternTypes: PatternTypes = patternTypes.find(p => p.pattern == signature.activePattern);

            pattern = {
                active: activePattern,
                activePatternTypes: activePatternTypes,
                beats: signature.beats,
                common: signature.common,
                patternTypes: patternTypes,
                speedBaseNote: activePatternTypes.speedBaseNote,
                timeBase: signature.timeBase,
                timeSignature: signature.beats + "/" + signature.timeBase
            };
        }
        return pattern;
    }

    private loadSoundSetInfo() {
        fetch("assets/sound-set-info.json").then(async (res) => {
            App.versionInfo = await (await fetch("assets/version.json")).json() as VersionRecord;

            this.soundSetInfo = await res.json();
            this.soundSetInfo.signatures.sort((a: Signature, b: Signature) => {
                let ret: number;
                if (a.common && !b.common) {
                    ret = -1;
                } else if (b.common && !a.common) {
                    ret = +1;
                } else {
                    if (a.timeBase < b.timeBase) {
                        ret = -1;
                    } else if (a.timeBase > b.timeBase) {
                        ret = +1;
                    } else {
                        if (a.beats < b.beats) {
                            ret = +1;
                        } else if (b.beats > a.beats) {
                            ret = -1;
                        } else {
                            ret = 0;
                        }
                    }
                }
                return ret;
            });

            this.soundSetInfo.signatures.forEach((signature) => {
                signature.patterns.sort();
            });

            if (this.state.customPatterns.length > 0) {
                this.state.customPatterns.forEach((customPattern) => {
                    this.updateSoundInfoCustomPattern(customPattern);
                });
            }

            this.resetStates();

            this.soundSet = await this.loadSoundSet(this.state.soundSetId);
            this.activeSignature = this.getSignaturePattern(this.state.activeTimeSignature),

                this.setState({
                    appReady: true
                }, () => {
                    App.onAppReady.next(true);
                });
        }).catch((error) => {
            console.error(error);
        });
    }

    private updateSoundInfoCustomPattern(customPattern: CustomPattern) {
        const soundSetSignature = this.soundSetInfo.signatures.find(s => s.beats + "/" + s.timeBase == customPattern.timeSignature);
        if (soundSetSignature) {
            const customPatternIdx = soundSetSignature.patterns.findIndex(p => p.match(/^C:/));
            if (customPatternIdx >= 0) {
                soundSetSignature.patterns[customPatternIdx] = customPattern.pattern;

            } else {
                soundSetSignature.patterns.push(customPattern.pattern);
            }

            soundSetSignature.patterns.sort();
        } else {
            console.error(`unable to add custom pattern for signature ${customPattern.timeSignature}`);
        }
    }

    private loadSoundSet(soundSetName: string): Promise<AudioBuffer[]> {
        return new Promise<AudioBuffer[]>(async (resolve, reject) => {
            try {
                if (this.soundSetInfo.soundSets[soundSetName]) {
                    const soundSet = this.soundSetInfo.soundSets[soundSetName];
                    let instrument: Record<string, string>;
                    if (soundSet.type == "json") {
                        const instrumentResponse = await fetch(this.soundSetInfo.soundFontRootUrl + "/json/" + soundSet.instrument);
                        instrument = await instrumentResponse.json();
                    }

                    const promises: Promise<AudioBuffer>[] = [];
                    soundSet.sounds.forEach((soundPath: string) => {
                        promises.push(new Promise<AudioBuffer>(async (resolve, reject) => {
                            try {
                                let audioBuffer: AudioBuffer;
                                if (instrument) {
                                    const bufferArray: Uint8Array = Uint8Array.from(Buffer.from(instrument[soundPath].replace(/^[^,]*,/, ""), "base64"));
                                    audioBuffer = await this.audioContext.decodeAudioData(bufferArray.buffer);
                                } else {
                                    const soundResponse: Response = await fetch(this.soundSetInfo.soundFontRootUrl + soundPath);
                                    audioBuffer = await this.audioContext.decodeAudioData(await soundResponse.arrayBuffer());
                                }

                                resolve(audioBuffer);
                            } catch (error) {
                                reject(error);
                            }
                        }));
                    });

                    Promise.all(promises).then((soundSets) => {
                        soundSets.unshift(null);
                        resolve(soundSets);
                    }).catch((error) => {
                        reject(error);
                    });
                } else {
                    reject(`no such sound set '${soundSetName}'`);
                }
            } catch (error) {
                reject(error);
            }
        });
    }
}