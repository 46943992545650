import {PersistenceChange, PersistentComponent, PersistentComponentContext} from "./lib/persistent-component";
import {Alert, AlertColor, Box, Collapse} from "@mui/material";
import {AppIpcInterface} from "./ipc/app-ipc";
import {Subject} from "rxjs";

interface ErrorMessage {
    error: Error;
    message: string;
    severity: AlertColor;
}

export enum DisplayMode {Dark = "dark", Light = "light"}

export interface AppComponentStates {
    country?: string;
    displayMode?: DisplayMode;
    locale?: string;
}

export class AppComponent<P extends {} = {}, S extends AppComponentStates = {}> extends PersistentComponent<P, S> {
    public static hasClickListener: boolean;
    public static lastClick: MouseEvent;
    //
    protected componentIsMounted: boolean = false;
    protected onComponentUpdate: Subject<void>;
    //
    private errorMessageStack: ErrorMessage[] = [];
    private errorMessageVisible: boolean;

    constructor(props: P, context: PersistentComponentContext<S>) {
        if (!context.persistentKeys) {
            context.persistentKeys = [];
        }

        const appComponentPersistentKeys: (keyof S)[] = ["locale", "displayMode"];
        context.persistentKeys.push(...appComponentPersistentKeys);
        super(props, context);

        this.onComponentUpdate = new Subject();

        if (!AppComponent.hasClickListener) {
            document.addEventListener("click", (ev) => {
                AppComponent.lastClick = ev;
            });
        }

        PersistentComponent.onPersistenceChange.subscribe((change: PersistenceChange) => {
            if (change.componentId != this.componentId) {
                if (appComponentPersistentKeys.includes(change.key as keyof S)) {
                    this.setStateSilent({[change.key]: change.value} as Record<keyof S, any>);
                }
            }
        });
    }

    public get electronApi(): AppIpcInterface {
        return globalThis.electronApi;
    }

    public componentDidUpdate() {
        this.onComponentUpdate.next();
    }

    public componentDidMount() {
        this.componentIsMounted = true;
    }

    public componentWillUnmount() {
        this.componentIsMounted = false;
    }

    public getDefaultStates(defaults?: S): S {
        const language = navigator.language ?? "en-US";
        const localeParts = language.split("-");
        return super.getDefaultStates(Object.assign({
            displayMode: DisplayMode.Dark,
            errorMessageVisible: false,
            locale: localeParts [0] ?? "en",
            country: localeParts[1] ?? "GB"
        }, defaults));
    }

    public displayMessage(message: string): void;

    public displayMessage(message: string, code: number): void;

    public displayMessage(message: string, severity: AlertColor): void;

    public displayMessage(error: Error): void;

    public displayMessage(error: Error, code: AlertColor): void;

    public displayMessage(error: Error, severity: AlertColor): void;

    public displayMessage(message: string | Error, severityOrCode?: AlertColor | number): void {
        if (message) {
            let error: Error;
            let severity: AlertColor = typeof severityOrCode == "number" ? this.severityFromCode(severityOrCode) : severityOrCode;
            if (typeof message == "string") {
                error = {
                    name: typeof severityOrCode == "number" ? severityOrCode.toString() : severityOrCode,
                    message: message
                };
            } else if (message instanceof Error) {
                error = message;
                message = message.message;
                console.error(message);
            } else {
                error = message;
                severity = severity ?? error.name as AlertColor;
                message = error.message;
            }

            this.errorMessageStack.unshift({
                message: message,
                severity: severity ?? "info",
                error: error
            });
            this.errorMessageVisible = true;
            this.refresh();
        }
    }

    public refresh(callback?: () => void) {
        this.setState({}, callback);
    }

    public toggleMode() {
        this.setState({
            displayMode: this.state.displayMode == DisplayMode.Dark ? DisplayMode.Light : DisplayMode.Dark
        });
    }

    protected injectAlertBox(asOverlay?: boolean) {
        return (
            <Box style={{paddingLeft: "10px", height: "49px", flex: 1, display: "relative"}}>
                <Collapse
                    in={this.errorMessageVisible}
                    onAnimationEnd={() => {
                        this.errorMessageStack.shift();
                        if (this.errorMessageStack.length > 0) {
                            if (AppComponent.lastClick?.altKey || AppComponent.lastClick?.ctrlKey) {
                                this.errorMessageStack.length = 0;
                            } else {
                                this.errorMessageVisible = true;
                                this.refresh();
                            }
                        }
                    }}
                >
                    <Alert
                        severity={this.errorMessageStack[0]?.severity}
                        onClose={() => {
                            this.errorMessageVisible = false;
                            this.refresh();
                        }}
                        style={{position: "absolute", width: "100%", top: 0, left: 0, zIndex: 10000}}
                    >
                        {this.errorMessageStack.length > 1 ? `[${this.errorMessageStack.length}] ` : ""}
                        {this.errorMessageStack[0]?.message}
                    </Alert>
                </Collapse>
            </Box>
        );
    }

    private severityFromCode(code: number): AlertColor {
        let severity: AlertColor;
        if (code < 0) {
            severity = "error";
        } else if (code === 0) {
            severity = "success";
        } else if (code < 100) {
            severity = "warning";
        } else {
            severity = "info";
        }

        return severity;
    }

    private codeFromSeverity(severity: AlertColor): number {
        let code: number;
        if (severity == "error") {
            code = -1;
        } else if (severity == "success") {
            code = 0;
        } else if (severity == "warning") {
            code = 1;
        } else {
            code = 100;
        }
        return code;
    }
}