import {BeatCircle, BeatCircleProps} from "./beat-circle";
import React, {Component} from "react";
import {Box} from "@mui/material";
import {ActiveSignature, PatternTypes, Signature} from "./app";
import _ from "lodash";

export interface BeatCircleBoxProps {
    activeBeat: number;
    activeSignature: ActiveSignature;
    soundSetLength: number;
    updatePatternTypes: (types: PatternTypes) => void;
    showNumbers:boolean;
}

export interface BeatCircleBoxStates {
}

export class BeatCircleBox extends Component<BeatCircleBoxProps, BeatCircleBoxStates> {
    private getBeatCircleProps(): BeatCircleProps[][] {
        const props: BeatCircleProps[][] = [];
        if (this.props.activeSignature.beats < 6) {
            props[0] = [];
            this.props.activeSignature.activePatternTypes.types.forEach((type, beat) => {
                props[0][beat] = {beat: beat, type: type, showNumber:this.props.showNumbers};
            });
        } else if (this.props.activeSignature.beats < 9) {
            props[0] = [];
            props[1] = [];
            const first = Math.trunc(this.props.activeSignature.beats / 2);
            this.props.activeSignature.activePatternTypes.types.forEach((type, beat) => {
                props[beat < first ? 0 : 1][beat] = {beat: beat, type: type, showNumber:this.props.showNumbers};
            });
        } else {
            props[0] = [];
            props[1] = [];
            props[2] = [];
            const first = Math.trunc(this.props.activeSignature.beats / 3);
            const second = first * 2;
            this.props.activeSignature.activePatternTypes.types.forEach((type, beat) => {
                props[beat < first ? 0 : (beat < second ? 1 : 2)][beat] = {beat: beat, type: type, showNumber:this.props.showNumbers};
            });
        }
        return props;
    }

    public render() {
        const beatCircleProps = this.getBeatCircleProps();
        return (
            <Box>
                {
                    beatCircleProps.map((rowProps, row) => {
                        return <Box style={{display: "flex", justifyContent: "center"}} key={`R${row}`}>
                            {
                                rowProps.map((props, col) => {
                                    return <BeatCircle
                                        key={`R${row}-C${col}`} {...props}
                                        active={props.beat == this.props.activeBeat}
                                        type={props.type}
                                        showNumber={props.showNumber}
                                        buttonClick={() => {
                                            const patternTypes = _.cloneDeep(this.props.activeSignature.activePatternTypes);
                                            patternTypes.types[props.beat] = (patternTypes.types[props.beat] + 1) % this.props.soundSetLength;
                                            this.props.updatePatternTypes(patternTypes);
                                        }}
                                    />;
                                })
                            }
                        </Box>;
                    })
                }
            </Box>
        );
    }
}