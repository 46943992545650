import {AppComponent, AppComponentStates, DisplayMode} from "./app-component";
import {Box, Divider, Drawer, FormControl, InputLabel, List, ListItem, ListItemButton, ListItemIcon, ListItemText, MenuItem, Select, Switch, Typography} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import LightModeIcon from "@mui/icons-material/LightMode";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import ResetSettingsIcon from "@mui/icons-material/RestartAlt";
import FlashOnIcon from '@mui/icons-material/FlashOn';
import FlashOffIcon from '@mui/icons-material/FlashOff';
import React from "react";
import {App, SoundSetInfo} from "./app";
import messages from "./messages";
import {FormattedMessage, IntlProvider} from "react-intl";
import ReactCountryFlag from "react-country-flag";
import {AdjustOutlined, LensOutlined} from "@mui/icons-material";

export interface SettingsProps {
    displayMode: DisplayMode;
    flashBackground: boolean;
    onClose: () => void;
    onDisplayModeChange: () => void;
    onFlashBackgroundChange: () => void;
    onReset: () => void;
    open: boolean;
    soundSetId: string;
    soundSetInfo: SoundSetInfo;
    updateSoundSet: (soundSetId: string) => void;
    showNumbers:boolean;
    onShowNumbersChange:()=>void
}

export interface SettingsStates extends AppComponentStates {
}

export class Settings extends AppComponent<SettingsProps, SettingsStates> {
    constructor(props: SettingsProps) {
        super(props, {persistentKeys: ["locale", "displayMode"]});
    }

    public render() {
        return (
            <IntlProvider locale={this.state.locale} messages={messages[this.state.locale]}>
                <Drawer
                    open={this.props.open}
                    anchor="right"
                    onClose={() => {
                        this.props.onClose();
                    }}
                >
                    <List style={{width: "300px"}}>
                        <ListItem>
                            <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
                                <FormattedMessage id="Settings"/>
                            </Typography>
                            <CloseIcon onClick={() => {
                                this.props.onClose();
                            }}/>
                        </ListItem>
                        <Divider style={{marginTop: "10px"}}/>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => {
                                this.props.onDisplayModeChange();
                            }}>
                                <ListItemIcon>
                                    {this.props.displayMode == DisplayMode.Dark ? <DarkModeIcon/> : <LightModeIcon/>}
                                </ListItemIcon>
                                <ListItemText primary={<FormattedMessage id="Display Mode"/>}/>
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => {
                                this.props.onFlashBackgroundChange();
                            }}>
                                <ListItemIcon>
                                    {this.props.flashBackground ? <FlashOnIcon/> : <FlashOffIcon/>}
                                </ListItemIcon>
                                <ListItemText primary={<FormattedMessage id="Flash Background"/>}/>
                                <Switch checked={this.props.flashBackground}></Switch>
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => {
                                this.props.onShowNumbersChange();
                            }}>
                                <ListItemIcon>
                                    {this.props.showNumbers ? <AdjustOutlined/> : <LensOutlined/>}
                                </ListItemIcon>
                                <ListItemText primary={<FormattedMessage id="Show Numbers"/>}/>
                                <Switch checked={this.props.showNumbers}></Switch>
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => {
                                this.clearPersistence(true);
                                this.props.onReset();
                            }}>
                                <ListItemIcon>
                                    <ResetSettingsIcon/>
                                </ListItemIcon>
                                <ListItemText primary={<FormattedMessage id="Reset Settings"/>}/>
                            </ListItemButton>
                        </ListItem>
                        <Divider style={{marginTop: "10px", marginBottom: "10px"}}/>
                        <ListItem disablePadding>
                            <FormControl sx={{margin: "10px", width: "100%"}}>
                                <InputLabel>
                                    <FormattedMessage id="Sound Set"/>
                                </InputLabel>
                                <Select
                                    label="Sound Set"
                                    size="small"
                                    value={this.props.soundSetId}
                                    onChange={async (ev) => {
                                        this.props.updateSoundSet(ev.target.value);
                                    }}
                                >
                                    {
                                        Object.keys(this.props.soundSetInfo.soundSets).map((key) => {
                                            return (
                                                <MenuItem
                                                    key={key}
                                                    value={key}
                                                    style={{fontFamily: "Noto Music", fontWeight: 400, fontStyle: "normal"}}
                                                >
                                                    {this.props.soundSetInfo.soundSets[key].name}
                                                </MenuItem>);
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </ListItem>
                        <Divider style={{marginTop: "10px", marginBottom: "10px"}}/>
                        <ListItem disablePadding>
                            <FormControl sx={{margin: "10px", width: "100%"}}>
                                <InputLabel><FormattedMessage id="Language"/></InputLabel>
                                <Select
                                    label={<FormattedMessage id="Language"/>}
                                    size="small"
                                    value={this.state.locale}
                                    onChange={async (ev) => {
                                        this.setState({
                                            locale: ev.target.value
                                        });
                                    }}
                                >
                                    {
                                        Object.keys(messages).map((locale) => {
                                            const language = messages[locale]["active.language"];
                                            let flag = messages[locale]["active.flag"];
                                            if (flag == "US" && this.state.country != "US") {
                                                flag = "GB";
                                            }

                                            return (
                                                <MenuItem key={locale} value={locale}>
                                                    <Box style={{width: "100%", display: "flex", alignItems: "center"}}>
                                                        <ReactCountryFlag svg countryCode={flag} style={{marginRight: "6px"}}/>
                                                        <Box style={{marginTop: 1}}>{language}</Box>
                                                    </Box>
                                                </MenuItem>
                                            );
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </ListItem>
                        <Divider style={{marginTop: "8px", marginBottom: "2px"}}/>
                        <ListItem style={{fontSize: "x-small", flexDirection: "column"}}>
                            <Box style={{flex: 0, display: "flex", width: "100%", flexDirection: "row"}}>
                                <div>Version {App.versionInfo.version}</div>
                                <div style={{flexGrow: 1}}/>
                                <div>Build {App.versionInfo.build} ({App.versionInfo.commitShort})</div>
                            </Box>
                            <Box style={{flex: 1}}></Box>
                            <Box style={{flex: 0, display: "flex", width: "100%", flexDirection: "row"}}>
                                <div>Pegasus Consulting GmbH</div>
                                <div style={{flexGrow: 1}}/>
                                <div>Copyright © 2024</div>
                            </Box>
                            <Box style={{display: "flex", width: "100%", flexDirection: "row"}}>
                                <div>8228 Beggingen</div>
                                <div style={{flexGrow: 1}}/>
                                <div>Switzerland</div>
                            </Box>
                        </ListItem>
                    </List>
                </Drawer>
            </IntlProvider>
        );
    }
}