import {AppComponent, AppComponentStates, DisplayMode} from "./app-component";
import {PersistentComponent, PersistentComponentContext} from "./lib/persistent-component";

export class AppWindow<P extends {} = {}, S extends AppComponentStates = { displayMode: DisplayMode.Dark }> extends AppComponent<P, S> {
    constructor(props: P, context: PersistentComponentContext<S>) {
        super(props, context);

        if (globalThis.electronApi) {
            this.linkElectronApi();
        }

        const bodyTag: HTMLBodyElement = document.getElementsByTagName("body")[0];

        PersistentComponent.onPersistenceChange.subscribe((change) => {
            if (change.key == "displayMode") {
                bodyTag.style.colorScheme = change.value;
                bodyTag.classList.remove("display-mode-light", "display-mode-dark");
                bodyTag.classList.add("display-mode-" + change.value.toLowerCase());
            }
        });
    }

    protected linkElectronApi() {
        this.electronApi.onRefresh(() => {
            //nothing to do right now
        });
    }
}