import {Component} from "react";
import "./css/beat-circle.css";

export interface BeatCircleProps {
    active?: boolean;
    beat: number;
    buttonClick?: () => void;
    showNumber: boolean;
    type: number;
}

export interface BeatCircleState {
}

export class BeatCircle extends Component<BeatCircleProps, BeatCircleState> {
    public render() {
        return <div className={"beat-type-" + this.props.type + (this.props.active ? " active" : "")}>
            <div
                className="beat-circle"
                onClick={() => this.props.buttonClick()}
            >
                <span style={{display: this.props.showNumber ? "inline-block" : "none"}}>{this.props.type}</span>
            </div>
        </div>;
    }
}