export default {
    en: {
        "Common": "Common",
        "Display Mode": "Display Mode",
        "Flash Background": "Flash Background",
        "Language": "Language",
        "Less Used": "Less Used",
        "Metronome": "Metronome",
        "Pattern": "Pattern",
        "Reset Settings": "Reset Settings",
        "Settings": "Settings",
        "Signatures": "Signatures",
        "Sound Set": "Sound Set",
        "active.flag": "US",
        "active.language": "English",
        "input-control.custom": "Custom",
        "input-control.in": "in"
    },
    de: {
        "Common": "Gebräuchlich",
        "Display Mode": "Anzeigemodus",
        "Flash Background": "Lichtpuls",
        "Language": "Sprache",
        "Less Used": "Unüblicher",
        "Metronome": "Metronom",
        "Pattern": "Rhythmus",
        "Reset Settings": "Alles zurücksetzen",
        "Settings": "Einstellungen",
        "Signatures": "Taktarten",
        "Sound Set": "Klangsätze",
        "active.flag": "DE",
        "active.language": "Deutsch",
        "input-control.custom": "Eigenes",
        "input-control.in": "in"
    }
};