import React, {Component} from "react";
import {Box, Fab, Slider} from "@mui/material";
import PauseIcon from "@mui/icons-material/Pause";
import PlayIcon from "@mui/icons-material/PlayArrow";
import VolumeMOffIcon from "@mui/icons-material/VolumeOff";
import VolumeMOffUpIcon from "@mui/icons-material/VolumeUp";

export interface PlayControlsProps {
    active: boolean,
    startStop: () => void
    volume: number;
    volumeChange: (volume: number) => void
}

export interface PlayControlsStates {
    volume: number;
}

export class PlayControls extends Component<PlayControlsProps, PlayControlsStates> {
    static lastVolume: number;
    private lastCommit: number = 0;

    constructor(props: PlayControlsProps) {
        super(props);
        this.state = {
            volume: this.props.volume
        };
    }

    private muteUnMute() {
        if (PlayControls.lastVolume) {
            this.props.volumeChange(PlayControls.lastVolume);
            PlayControls.lastVolume = 0;
        } else {
            PlayControls.lastVolume = this.props.volume;
            this.props.volumeChange(0);
        }
    }

    public render() {
        return (
            <Box style={{display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", marginTop: "20px"}}>
                <Box style={{display: "flex", alignItems: "center"}}>
                    {this.props.volume === 0 ? <VolumeMOffIcon onClick={() => this.muteUnMute()}/> : <VolumeMOffUpIcon onClick={() => this.muteUnMute()}/>}
                    <Slider
                        sx={{marginLeft: "20px"}}
                        value={this.state.volume}
                        min={0}
                        max={1}
                        step={0.05}
                        style={{width: "240px"}}
                        size="medium"
                        valueLabelDisplay="auto"
                        onChange={(ev, value: number) => {
                            const delta = Date.now() - this.lastCommit;
                            if (delta > 200) {
                                this.setState({
                                    volume: value
                                });
                            }
                        }}
                        onChangeCommitted={(ev, value: number) => {
                            const delta = Date.now() - this.lastCommit;
                            if (delta > 200) {
                                this.props.volumeChange(value);
                                this.lastCommit = Date.now();
                            }
                        }}
                    />
                </Box>
                <Box style={{display: "flex", marginLeft: "20px"}}>
                    <Fab size="large" onClick={() => this.props.startStop()}>
                        {this.props.active ? <PauseIcon/> : <PlayIcon/>}
                    </Fab>
                </Box>
            </Box>
        );
    }
}