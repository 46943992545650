import {Subject} from "rxjs";

export class Metronome {
    public onBeat: Subject<number>;
    private interval = 1000; // ms
    private timer: NodeJS.Timeout;

    constructor() {
        this.onBeat = new Subject<number>();
    }

    public get isActive(): boolean {
        return !!this.timer;
    }

    public setBpm(bpm: number) {
        this.interval = 60000 / bpm;
    }

    public start() {
        this.beat(Date.now());
    }

    public stop() {
        clearTimeout(this.timer);
        this.timer = null;
    }

    private beat(expected: number) {
        const now = Date.now();
        var dt = now - expected; // the drift (positive for overshooting)
        if (dt > this.interval) {
            // something really bad happened. Maybe the browser (tab) was inactive?
            // possibly special handling to avoid futile "catch up" run
        }

        this.onBeat.next(now);
        expected += this.interval;
        this.timer = setTimeout(() => this.beat(expected), Math.max(0, this.interval - dt)); // take into account drift
    }
}