import React from "react";
import {Box, Fab, FormControl, InputLabel, ListSubheader, MenuItem, Select, Slider} from "@mui/material";
import {ActiveSignature, PatternTypes, Signature, SoundSetInfo} from "./app";
import {AppComponent, AppComponentStates} from "./app-component";
import SpeedIcon from "@mui/icons-material/Speed";
import messages from "./messages";
import {FormattedMessage} from "react-intl";

export interface InputControlsProps {
    activeSignature: ActiveSignature;
    bpm: number;
    soundSetInfo: SoundSetInfo;
    updateBpm: (bpm: number) => void;
    updatePattern: (pattern: string) => void;
    updateSignature: (signature: Signature) => void;
}

export interface InputControlsStates extends AppComponentStates {
    bpm: number;
    isTapping: NodeJS.Timeout;
    showTempo: boolean;
}

export class InputControls extends AppComponent<InputControlsProps, InputControlsStates> {
    private debugMessage: string = "";
    private lastCommit: number = 0;
    private taps: number[];

    constructor(props: InputControlsProps) {
        super(props, {persistentKeys: ["showTempo"]});
    }

    public getDefaultStates(): InputControlsStates {
        return super.getDefaultStates({
            isTapping: null,
            showTempo: false,
            bpm: this.props.bpm
        });
    }

    private getTempoName(bpm: number): string {
        const tempi = this.props.soundSetInfo.tempi.filter((t) => t.bpmLow <= bpm && t.bpmHigh > bpm).map(t => t.name);
        return tempi.join(" / ");
    }

    private updateTapBpm() {
        if (this.taps.length > 1) {
            let sum = 0;
            for (let i = 1; i < this.taps.length; i++) {
                sum += this.taps[i] - this.taps[i - 1];
            }
            const avg = sum / (this.taps.length - 1);
            const bpm = Math.min(Math.round(60000 / avg), 240);
            this.props.updateBpm(bpm);
        }
    }

    private getPatternTypeNotes(signature: ActiveSignature, patternTypes: PatternTypes) {
        let notes: string = "";
        if (patternTypes.pattern.match(/^C:/)) {
            notes = signature.timeSignature + " - " + messages[this.state.locale]["input-control.custom"];
        } else {
            const baseLength = 1 / signature.timeBase;
            const baseNote = this.props.soundSetInfo.notes.find(n => n.length == baseLength);
            const baseRest = this.props.soundSetInfo.rests.find(n => n.length == baseLength);

            const note = baseNote ? baseNote.label : "?";
            const rest = baseRest ? baseRest.label : "z";

            let groups: string[] = patternTypes.pattern.split("-");

            let parts: string[];
            if (groups.length > 1 || patternTypes.pattern.match(/[:+]/)) {
                parts = groups;
            } else {
                parts = new Array(signature.beats).fill("1");
            }

            parts.forEach((subPattern: string) => {
                const match = subPattern.match(/[:+]/);
                const separator: string = match && match[0];
                const group: number[] = subPattern.replace(/:/g, "+").split("+").map(p => parseInt(p));
                group.forEach((len: number, idx: number) => {
                    if (len === 0) {
                        notes += rest;
                    } else {
                        notes += note;

                        for (let i = 1; i < len; i++) {
                            if (separator == ":") {
                                notes += note;
                            } else {
                                notes += rest;
                            }
                        }
                    }

                    if ((groups.length > 1 && idx == group.length - 1) || separator == ":") {
                        notes += "  ";
                    }
                });
            });
        }

        return notes;
    }

    public render() {
        const groupedSignatures = {
            common: [],
            uncommon: []
        };

        this.props.soundSetInfo.signatures.forEach((signature: Signature) => {
            if (signature.common) {
                groupedSignatures.common.push(signature);
            } else {
                groupedSignatures.uncommon.push(signature);
            }
        });

        let hasTouch: boolean = false;

        return (
            <Box>
                <Box style={{display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", marginTop: "40px"}}>
                    <FormControl sx={{width: "120px"}}>
                        <InputLabel><FormattedMessage id="Signatures"/></InputLabel>
                        <Select
                            style={{fontFamily: "Noto Music", fontWeight: 400, fontStyle: "normal", fontSize: "larger"}}
                            size="small"
                            label={<span className="app-default-font" style={{fontSize: "smaller"}}><FormattedMessage id="Signatures"/></span>}
                            value={this.props.activeSignature.timeSignature}
                            onChange={(ev) => {
                                const signature = this.props.soundSetInfo.signatures.find(s => ev.target.value == s.beats + "/" + s.timeBase);
                                if (signature) {
                                    this.props.updateSignature(signature);
                                } else {
                                    console.log(`unable to find signature for time signature ${ev.target.value}`);
                                }
                            }}
                        >
                            {
                                Object.keys(groupedSignatures).map((header) => {
                                    return [
                                        <ListSubheader
                                            key={`pattern-${header}`}
                                            style={{backgroundColor: "unset", lineHeight: "18px", fontWeight: "bold", color: "grey", marginTop: "6px", textTransform: "uppercase", userSelect: "none", cursor: "pointer"}}
                                        >
                                            <FormattedMessage id={header == "common" ? "Common" : "Less Used"}/>
                                        </ListSubheader>,
                                        groupedSignatures[header].map((signature: Signature, idx: number) => {
                                            return <MenuItem
                                                key={`pattern-${header}-${idx}}`}
                                                value={signature.beats + "/" + signature.timeBase}
                                                style={{fontFamily: "Noto Music", fontWeight: 400, fontStyle: "normal", fontSize: "larger"}}
                                            >
                                                <div style={{display: "flex", width: "100%", alignItems: "center"}}>
                                                    <span style={{float: "left"}}> {signature.beats + "/" + signature.timeBase}</span>
                                                </div>
                                            </MenuItem>;
                                        })
                                    ];
                                })
                            }
                        </Select>
                    </FormControl>
                    <FormControl sx={{marginLeft: "10px", width: "230px"}}>
                        <InputLabel><FormattedMessage id="Pattern"/></InputLabel>
                        <Select
                            style={{fontFamily: "Noto Music", fontWeight: 400, fontStyle: "normal", fontSize: "larger"}}
                            label={<span className="app-default-font" style={{fontSize: "smaller"}}><FormattedMessage id="Pattern"/></span>}
                            size="small"
                            value={this.props.activeSignature.active}
                            onChange={(ev) => {
                                this.props.updatePattern(ev.target.value);
                            }}
                        >
                            {
                                this.props.activeSignature.patternTypes.map((patternTypes: PatternTypes, idx: number) => {
                                    return (
                                        <MenuItem
                                            key={`time-base-${idx}}`}
                                            value={patternTypes.pattern}
                                            style={{fontFamily: "Noto Music", fontWeight: 400, fontStyle: "normal", fontSize: "larger"}}
                                        >
                                            <div style={{display: "flex", width: "100%", alignItems: "center"}}>
                                                <div>{this.getPatternTypeNotes(this.props.activeSignature, patternTypes)}</div>
                                                <div style={{flexGrow: 1}}></div>
                                                <div>
                                                    <span style={{fontSize: "smaller"}}>
                                                        <FormattedMessage id="input-control.in"/>
                                                    </span>&nbsp;{patternTypes.speedBaseNote}</div>
                                            </div>
                                        </MenuItem>
                                    );
                                })
                            }
                        </Select>
                    </FormControl>
                </Box>
                <Box style={{display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", marginTop: "30px"}}>
                    <Box style={{display: "flex", alignItems: "center", position: "relative"}}>
                        <Box style={{position: "absolute", top: "36px", left: 0, fontSize: "small"}}>
                            {this.state.showTempo ? this.getTempoName(this.state.bpm) : ""}
                        </Box>
                        <SpeedIcon onClick={() => {
                            this.setState({
                                showTempo: !this.state.showTempo
                            });
                        }}/>
                        <Slider
                            aria-label="speed bpm"
                            sx={{marginLeft: "20px", width: "240px"}}
                            value={this.state.bpm}
                            min={this.props.soundSetInfo.minBpm}
                            max={this.props.soundSetInfo.maxBpm}
                            step={this.props.bpm < 30 ? 1 : 2}
                            shiftStep={8}
                            size="medium"
                            valueLabelDisplay="auto"
                            valueLabelFormat={(bpm: number) => <div>{this.state.showTempo ? bpm : this.getTempoName(bpm)}</div>}
                            onChange={(ev, value: number) => {
                                const delta = Date.now() - this.lastCommit;
                                if (delta > 200) {
                                    if (this.state.isTapping) {
                                        clearTimeout(this.state.isTapping);
                                    }

                                    this.setState({
                                        isTapping: null,
                                        bpm: value
                                    });
                                }
                            }}
                            onChangeCommitted={(ev, value: number) => {
                                const delta = Date.now() - this.lastCommit;
                                if (delta > 200) {
                                    this.props.updateBpm(value);
                                    this.lastCommit = Date.now();
                                }
                            }}
                        />
                    </Box>
                    <Box style={{display: "flex", marginLeft: "20px"}}>
                        <Fab
                            size="large"
                            color={this.state.isTapping ? "warning" : "primary"}
                            style={{fontWeight: "bold"}}
                            onClick={() => {
                                if (this.state.isTapping) {
                                    clearTimeout(this.state.isTapping);
                                    this.taps.push(Date.now());
                                    if (this.taps.length > 4) {
                                        this.taps.shift();
                                    }
                                    this.updateTapBpm();
                                } else {
                                    this.taps = [Date.now()];
                                }

                                this.setState({
                                    isTapping: setTimeout(() => {
                                        this.setState({
                                            isTapping: null
                                        }, () => {
                                            this.updateTapBpm();
                                        });
                                    }, 3000)
                                });
                            }}
                        >
                            {this.state.bpm}
                        </Fab>
                    </Box>
                </Box>
                <Box>{this.debugMessage}</Box>
            </Box>
        );
    }
}