import {Component} from "react";
import {Subject} from "rxjs";

export interface PersistenceChange {
    componentId: number;
    key: string;
    oldValue: any;
    value: any;
}

export interface PersistentComponentContext<S> {
    persistentKeys?: (keyof S)[];
}

export class PersistentComponent<P extends {} = {}, S extends {} = {}> extends Component<P, S> {
    static lastComponentId: number = 0;
    static onPersistenceChange: Subject<PersistenceChange>;
    //
    public componentId: number;
    //
    private readonly persistentKeys = [];

    constructor(props: P, context: PersistentComponentContext<S>) {
        super(props);

        this.componentId = ++PersistentComponent.lastComponentId;

        if (!PersistentComponent.onPersistenceChange) {
            PersistentComponent.onPersistenceChange = new Subject<PersistenceChange>;
        }

        this.persistentKeys = context.persistentKeys ?? [];

        if (!window.localStorage) {
            console.error(`this browser doesn't support local storage - persistence disabled for '${this.constructor.name}'`);
        }

        this.state = this.getDefaultStates();

        setTimeout(() => {
            Object.keys(this.state).forEach((key) => {
                PersistentComponent.onPersistenceChange.next({
                    componentId: this.componentId,
                    key: key,
                    value: this.state[key],
                    oldValue: undefined
                });
            });
        });
    }

    public clearPersistence(all?: boolean) {
        if (window.localStorage) {
            if (all) {
                window.localStorage.clear();
            } else {
                this.persistentKeys.forEach((key: string) => {
                    window.localStorage.removeItem(key);
                });
            }
            this.resetStates(true);
        }
    }

    public getDefaultStates(defaults?: S): S {
        const defaultStates: S = Object.assign({}, defaults);
        if (window.localStorage) {
            this.persistentKeys.forEach((key: string) => {
                const persistenceValue = JSON.parse(window.localStorage.getItem(key));
                if (persistenceValue !== null) {
                    defaultStates[key] = persistenceValue;
                }
            });
        }
        return defaultStates;
    }

    public resetStates<K extends keyof S>(apply?: boolean, overrides?: Pick<S, K>) {
        if (apply) {
            this.setState(Object.assign({}, this.getDefaultStates(), overrides ?? {}));
        } else if (this.state) {
            Object.assign(this.state, this.getDefaultStates(), overrides ?? {});
        } else {
            this.state = Object.assign({}, this.getDefaultStates(), overrides ?? {});
        }
    }

    public setStateSilent<K extends keyof S>(state: ((prevState: Readonly<S>, props: Readonly<P>) => (Pick<S, K> | S | null)) | Pick<S, K> | S | null, callback?: () => void) {
        this.setState(state, callback, true);
    }

    public setState<K extends keyof S>(state: ((prevState: Readonly<S>, props: Readonly<P>) => (Pick<S, K> | S | null)) | Pick<S, K> | S | null, callback?: () => void, silent?: boolean) {
        super.setState(state, () => {
            if (!silent) {
                Object.keys(state).forEach((key) => {
                    if (window.localStorage && this.persistentKeys.includes(key)) {
                        const oldValue = this.state[key];
                        window.localStorage.setItem(key as string, JSON.stringify(this.state[key]));
                        PersistentComponent.onPersistenceChange.next({
                            componentId: this.componentId,
                            key: key,
                            value: this.state[key],
                            oldValue: oldValue
                        });
                    }
                });
            }

            if (callback) {
                callback();
            }
        });
    }

    protected getStateValue<K extends keyof S>(key: K) {
        return this.state[key];
    }

    protected setStateValue(key: string, value?: any): void {
        const s: Partial<S> = {};
        s[key] = value;
        this.setState(s as S);
    }
}